import React from 'react';
import './styles/FooterContent.css';

const FooterContentBlock = (properties) => {
    return (
        <div className={'Footer_Content'}>
            <div className={'fix'}>
                <img src={require('../images/' + properties.imgSrc)} alt={properties.imgSrc} />
                <a href={properties?.type ? properties?.type + properties.infoText : ''} style={(properties?.type) ? {cursor: 'pointer'} : {pointerEvents: 'none'}} >{properties.infoText}</a>
            </div>
        </div>
    );
};

export default FooterContentBlock;