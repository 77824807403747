import React from 'react';

const PseudoCol = (properties) => {
    return (
        <div className={'Pseudo_Col'}>
            <p>{properties.data}</p>
        </div>
    );
};

export default PseudoCol;