import './styles/Layout.css'
import React from 'react';
import {Outlet} from "react-router-dom";
import LogoImage from '../images/Logo.svg';
import FooterContentBlock from "./FooterContentBlock";

const Layout = () => {

    return (
        <>
            <main>
                <div className={'Fixer'}>
                    <header>
                        <div className={'Header_Container'}>
                            <img src={LogoImage} alt={'Logo'} />
                            <a href="tel:+74952555090">
                                <div className={'Call'}>
                                    <p>Позвонить</p>
                                </div>
                            </a>
                        </div>
                    </header>


                    <Outlet />


                    <footer>
                        <FooterContentBlock type={'tel:'} imgSrc={'Phone_Icon.svg'} infoText={'+74952555090'} />
                        <FooterContentBlock type={'mailto:'} imgSrc={'Inbox_Icon.svg'} infoText={'dr@autotexcentr.ru'} />
                        <FooterContentBlock imgSrc={'Clock_Icon.svg'} infoText={'09:00 - 21:00'} />
                        <FooterContentBlock imgSrc={'Pin_Icon.svg'} infoText={'ул. Новохохловская 11, стр.2'} />
                    </footer>

                </div>

            </main>
        </>
    );
};

export default Layout;