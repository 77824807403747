import React from 'react';
import './styles/Main.css';
import Changer from "./Changer";

const Main = () => {
    return (
        <div className={'Main'}>
            <Changer />
        </div>
    );
};

export default Main;