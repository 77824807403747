import React, {useEffect, useState} from 'react';
import './styles/Table.css'
import PseudoTable from "./PseudoTable";
import {Link, useParams} from "react-router-dom";

const Table = () => {
    // const [dater, setDater] = useState(null);
    // const {id} = useParams();
    // useEffect(() => {
    //     fetch('http://localhost:4000/Table/:' + id)
    //         .then(res => res.json())
    //         .then(res => {
    //             setDater(res);
    //         })
    // }, [id]);

    return (
        <div className={'Table_Container'}>
            <div>
                <PseudoTable data={'Работы по ТО'} dataSale={'Стоимость'}/>
                <PseudoTable data={'Замена масла ДВС + фильтр'} dataSale={'960'}/>
                <PseudoTable data={'Замена воздушного фильтра'} dataSale={'480'}/>
                <PseudoTable data={'Замена фильтра салона'} dataSale={'640'}/>
                <PseudoTable data={'Диагностика ходовой (бесплатно при ремонте ходовой)'} dataSale={'1280'}/>
                <PseudoTable data={'Передние тор. колодки (замена + чистка и смазка направляющих)'} dataSale={'2080'}/>
                <PseudoTable data={'Задние тор. колодки (замена + чистка и смазка направляющих)'} dataSale={'2080'}/>
                <PseudoTable data={'Замена передних тор. дисков'} dataSale={'4800'}/>
                <PseudoTable data={'Замена задних тор. дисков'} dataSale={'4800'}/>
                <PseudoTable data={'Замена тормозной жидкости'} dataSale={'1920'}/>
                <PseudoTable data={'Замена масла АКПП (с фильтром)'} dataSale={'3840'}/>
                <PseudoTable data={'Сход-развал'} dataSale={'4000'}/>

                {/*{*/}
                {/*    dater && dater.map((el) => {*/}
                {/*        return <PseudoTable key={el._id} data={el.work} dataSale={el.sale}/>*/}
                {/*    })*/}
                {/*}*/}

                <Link to={'/'} className={'GoBack'}>
                    Вернуться
                </Link>
            </div>
        </div>
    );
};

export default Table;