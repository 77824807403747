import React from 'react';
import PseudoCol from "./PseudoCol";
import './styles/PseudoTable.css'

const PseudoTable = (properties) => {
    return (
        <div className={'Pseudo_Table'}>
            <PseudoCol data={properties.data}/>
            <PseudoCol data={properties.dataSale}/>
        </div>
    );
};

export default PseudoTable;