import React from 'react';
import ChangerContent from "./ChangerContent";
import "./styles/Changer.css"

const Changer = () => {
    return (
        <div className={'Changer'}>
            <h1 style={{display: "none", textTransform: "uppercase",}}>ЦЕНЫ НА ПОПУЛЯРНЫЕ ВИДЫ РАБОТ И ТЕХНИЧЕСКО ОБСЛУЖИВАНИЕ АВТОМОБИЛЕЙ BMW, MERCEDES и AUDI В ГОРОДЕ МОСКВЕ Стоимость каждой работы, представленной в таблице, является
                ориентировочной и может незначительно отличаться в зависимости от конкретной модели автомобиля и
                степени износа его агрегатов. Существует система скидок на работы и запасные части, подробности
                уточняйте у менеждера. Обязательно наличие документов на автомобиль.
                В отделе запчастей нашего техцентра вы можете заказать оригинальные и неоригинальные запасные
                части, которые выпускаются на заводах-поставщиках конвейеров BMW, Mercedes-Benz, Audi.</h1>
            <h2>ЦЕНЫ НА ПОПУЛЯРНЫЕ ВИДЫ РАБОТ И ТО:</h2>
            <ChangerContent />
        </div>
    );
};

export default Changer;