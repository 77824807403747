import React from 'react';
import "./styles/ChangerContent.css"
import ChangerContentImg from "./ChangerContentImg";

const ChangerContent = () => {
    return (
        <div className={'Changer_Content'}>
            <ChangerContentImg imgSrc={'BMW.svg'} id={'BMW'}/>
            <ChangerContentImg imgSrc={'Mercedes.svg'} id={'Mercedes'}/>
            <ChangerContentImg imgSrc={'Audi.png'} id={'Audi'}/>
        </div>
    );
};

export default ChangerContent;