import React from 'react';
import Table from "./Table";
import './styles/Sales.css';

const Sales = () => {
    return (
        <div className={'Sales'}>

            <Table />

            <div className={'NDS'}>
                <h2 style={{display: "none"}}>Стоимости работ указаны в рублях</h2>
                <p className={'NDS_Text'}>Стоимость каждой работы, представленной в таблице, является
                    ориентировочной и может незначительно отличаться в зависимости от конкретной модели автомобиля и
                    степени износа его агрегатов. Существует система скидок на работы и запасные части, подробности
                    уточняйте у менеждера. Обязательно наличие документов на автомобиль.
                    В отделе запчастей нашего техцентра вы можете заказать оригинальные и неоригинальные запасные
                    части, которые выпускаются на заводах-поставщиках конвейеров BMW, Mercedes-Benz, Audi.</p>
            </div>
        </div>
    );
};

export default Sales;