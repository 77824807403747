import React from 'react';
import './styles/ChagerContentImg.css';
import {Link} from "react-router-dom";

const ChangerContentImg = (properties) => {
    return (
        <div className={'fixer2'}>
            <img className={'Changer_Content_Img'} src={require('../images/' + properties.imgSrc)}
                 alt={properties.imgSrc.split('.')[0]}/>
            <Link to={'/Table/' + properties.id} className={'Changer_Img_Link'}>
                Узнать
            </Link>
        </div>
    );
};

export default ChangerContentImg;