import {Route, Routes} from "react-router-dom";
import Layout from "./components/Layout";
import Main from "./components/Main";
import './App.css';
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Sales from "./components/Sales";

function App() {

    return (
        <div className={'App'}>
            <Routes>
                <Route path={'/'} element={<Layout />}>
                    <Route path={'/'} element={<Main />}/>
                    <Route path={'/Table'} element={<Sales />}>
                        <Route path=":id" element={<Sales />} />
                    </Route>
                    <Route path={'*'} element={<Main/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
